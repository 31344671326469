import './style.css'

const Policy = () => {
	return (
		<div className="flex flex-col">
<div class="privacy-policy">
    <h2>Chính Sách Bảo Mật</h2>
    
    <p>Chúng tôi cam kết bảo vệ thông tin cá nhân của khách hàng khi sử dụng dịch vụ đặt vé xe trên website của chúng tôi. Chính sách bảo mật này nhằm giải thích cách chúng tôi thu thập, sử dụng và bảo vệ dữ liệu của bạn.</p>
    
    <div class="section">
        <h3>1. Thu thập thông tin</h3>
        <p>Chúng tôi thu thập thông tin cá nhân như họ tên, số điện thoại, email, địa chỉ và thông tin thanh toán khi bạn đặt vé trên hệ thống. Ngoài ra, chúng tôi cũng có thể lưu trữ lịch sử đặt vé và phản hồi của bạn để cải thiện dịch vụ.</p>
    </div>

    <div class="section">
        <h3>2. Sử dụng thông tin</h3>
        <p>Thông tin cá nhân của bạn được sử dụng để:</p>
        <ul>
            <li>Xử lý đơn đặt vé và gửi xác nhận.</li>
            <li>Hỗ trợ khách hàng và giải quyết khiếu nại.</li>
            <li>Cung cấp thông tin khuyến mãi hoặc cập nhật quan trọng liên quan đến dịch vụ (nếu bạn đồng ý nhận).</li>
        </ul>
    </div>

    <div class="section">
        <h3>3. Bảo mật thông tin</h3>
        <p>Chúng tôi áp dụng các biện pháp bảo mật như mã hóa dữ liệu, tường lửa và xác thực để bảo vệ thông tin cá nhân khỏi truy cập trái phép, rò rỉ hoặc mất mát.</p>
    </div>

    <div class="section">
        <h3>4. Chia sẻ thông tin</h3>
        <p>Chúng tôi cam kết không bán, trao đổi hoặc chia sẻ thông tin cá nhân của bạn với bên thứ ba, trừ khi có yêu cầu từ cơ quan pháp luật hoặc đối tác dịch vụ thanh toán cần thiết cho quá trình đặt vé.</p>
    </div>

    <div class="section">
        <h3>5. Quyền lợi của khách hàng</h3>
        <p>Bạn có quyền yêu cầu chỉnh sửa, cập nhật hoặc xóa thông tin cá nhân của mình. Nếu có bất kỳ thắc mắc nào về chính sách bảo mật, vui lòng liên hệ với chúng tôi qua <strong>[pkdvexevn@gmail.com hoặc 0972221221]</strong>.</p>
    </div>

    <p><em>Chính sách này có thể được cập nhật theo thời gian để phù hợp với các quy định mới.</em></p>
</div>

		</div>
	);
};

export default Policy;
